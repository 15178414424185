<template>
  <div style="background-color:#F1F3FF;border-bottom: 24px solid #F1F3FF;">
    <el-row >
      <el-col>
        <div class="box">
          <div class="icon">
            <img src="@/assets/img/icon／sys／totalorder@2x.png" alt="" />
          </div>
          <div class="num"><span>{{titleList.allSum}}</span></div>
          <div class="tag"><span>{{td('所有销售订单')}}</span></div>
        </div>
      </el-col>
      <el-col>
		<router-link to="/ERP/audit" >
        <div class="box" @click="setAction">
          <div class="icon">
            <img src="@/assets/img/icon／sys／pendingreview@2x.png" alt="" />
          </div>
          <div class="num"><span>{{titleList.waitAuditSum}}</span></div>
           <div class="tag" ><span>{{td('待审核订单')}}</span></div>
        </div>
		</router-link>
      </el-col>
      <el-col>
        <div class="box">
          <div class="icon">
            <img src="@/assets/img/icon／sys／down@2x.png" alt="" />
          </div>
          <div class="num">{{titleList.auditSum}}</div>
          <div class="tag">{{td('已审核订单')}}</div>
        </div>
      </el-col>
      <el-col >
        <div class="box">
          <div class="icon">
            <img src="@/assets/img/icon／sys／shipped@2x.png" alt="" />
          </div>
          <div class="num"><span>{{titleList.shipedSum}}</span></div>
          <div class="tag"><span>{{td('已发货订单')}}</span></div>
        </div>
      </el-col>
      <el-col >
        <div class="box">
          <div class="icon">
            <img src="@/assets/img/icon／sys／receivedorder@2x.png" alt="" />
          </div>
          <div class="num"><span>{{titleList.signSum}}</span></div>
          <div class="tag"><span>{{td('签收订单')}}</span></div>
        </div>
      </el-col>
      
      
    </el-row>

    <div class="tables">
      <div class="titles"><h4>{{td('所有订单')}}</h4></div>
      <el-form  ref="query" :inline="true" label-width="100px" label-position="left">
        <el-form-item :label="td('查询日期')" >
          <el-date-picker class="selectTime" size="small" v-model="timeScope" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
          :default-time="['00:00:00', '23:59:59']" :start-placeholder='td("开始日期")' range-separator="~" :end-placeholder='td("结束日期")' />
        </el-form-item>
		<el-form-item :label="td('店铺')" >
		  <el-select v-model="queryParams.shopId" :placeholder="td('请选择店铺')"  clearable size="small" class="itemShop">
		    <el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId" size="mini" />
		  </el-select>
		</el-form-item>
		<el-form-item :label="td('订单状态')" >
		  <el-select v-model="queryParams.orderState" :placeholder="td('订单状态')" clearable>
		    <el-option v-for="dict in tf('ERP_STATE' )" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId" />
		  </el-select>
		</el-form-item>
        <el-form-item style="margin-left : 22px">
          <el-button type="primary" size="small" @click="query">{{ td("搜索") }}</el-button>
          <el-button  size="small" @click="rest">{{ td("重置") }}</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tablList" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }" :row-style="{height: '80px'}" >
        <el-table-column prop="shopName" :label='td("店铺名")' >
        </el-table-column>
        <el-table-column prop="consigneeCountry" :label='td("国家")' >
        </el-table-column>
        <el-table-column :label='td("平台")' >
          <template slot-scope="scope">{{ tfp("ERP_ORDER_SOURCE",scope.row.source) }}</template>
        </el-table-column>
        <el-table-column prop="orderNo" :label='td("订单编号")' >
        </el-table-column>
        <el-table-column :label='td("订单状态")' align="center">
          <template slot-scope="scope">{{ tfp("ERP_STATE",scope.row.orderState) }}</template>
        </el-table-column>
        <!-- <el-table-column prop="createBy" :label='td("操作人员")' ></el-table-column> -->
        <el-table-column prop="createTime" :label='td("订单时间")' >
        </el-table-column>
        <el-table-column prop="remark" :label='td("备注")' > </el-table-column>
      </el-table>

      <pagination :total="td(total)" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="handlgetList"/>

    </div>

    <!-- 导入按钮 -->
    <el-dialog :title="td('修改密码')" :visible.sync="changePassWordCon" width="500px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <div style="display:flex;justify-content: center;margin-bottom:20px"><span style="color:red">{{this.td('登录超过90天，请修改密码!')}}</span></div>
      <el-form ref="form" :model="user" :rules="rules" label-width="80px">
        <el-form-item :label="td('旧密码')" prop="oldPassword">
          <el-input v-model="user.oldPassword" :placeholder="td('请输入旧密码')" type="password" show-password/>
        </el-form-item>
        <el-form-item :label="td('新密码')" prop="newPassword">
          <el-input v-model="user.newPassword" :placeholder="td('请输入新密码')" type="password" show-password/>
        </el-form-item>
        <el-form-item :label="td('确认密码')" prop="confirmPassword">
          <el-input v-model="user.confirmPassword" :placeholder="td('请确认密码')" type="password" show-password/>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button  style="margin-right:15px;" @click="close">{{td("取 消")}}</el-button>
        <el-button type="primary" @click="submit" >{{td("确 定")}}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getTitle } from '@/api/page'
// import { getList } from '@/api/page'
import { updateUserPwd } from "@/api/system/user";
import { getOrderList} from "@/api/order/orderList";
 import { getshop } from '@/api/warehouse'
 import Cookies from "js-cookie";
import { Throttle, addDateRange } from "@/utils/bd.js"; 
 
export default {
  name: "showTitle",
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error(this.td("两次输入的密码不一致")));
      } else {
        callback();
      }
    };
    //验证密码的规则
    const checkEmail = (rule, value, cb) => {
      //验证密码的正则表达式（包含数字、字母大小写、特殊字符）
      const regEmail = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_.,/;:!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^.,/;:&*`~()-+=]+$)(?![0-9\\W_!@#.,/;:$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#.,/;:$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$.,/;:%^&*`~()-+=]{8,30}$/;
      if (regEmail.test(value)) {
        //合法密码
        return cb();
      }
      cb(new Error( this.td("密码须包含大小写字母、数字以及特殊字符，且长度在8到30位")));
    };
    return {
      titleList:[],
      total:0,
      timeScope: [],
      tablList:[],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        beginTime: null,
        endTime : null,
      },
	  ShopList: [],
      //弹窗组件
      changePassWordCon: false,
      user: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
      },
      // 表单校验
      rules: {
        oldPassword: [
          { required: true, message: this.td("旧密码不能为空"), trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: this.td("新密码不能为空"), trigger: "blur" },
          { validator: checkEmail, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: this.td("确认密码不能为空"), trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ]
      }
    }
  },
  computed: {
    pickerOptions() {
      return {
          shortcuts: [{
            text: this.td('本日'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: this.td('最近一周'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.td('最近一个月'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.td('最近三个月'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: this.td('最近六个月'),
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90 * 2);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
  },
  created() {
    this.getTitleList()
    this.handlgetList()
    this.watchPassword()
	this.getShop()
  },
  //进入页面进行刷新，使翻译生效
  mounted: function() {
      if (location.href.indexOf("#reloaded") == -1) {
          location.href = location.href + "#reloaded";
          location.reload();
      }
  },
  methods: {
    getTitleList() {
      getTitle().then( res => {
        this.titleList = res.data
        
      })
    },
	getShop() {       //获取店铺
	  getshop().then((res) => {
	    this.ShopList = res.rows;
	  })
	},
    handlgetList() {
      // this.queryParams.beginTime = this.timeScope[0] || null
      // this.queryParams.endTime = this.timeScope[1] || null
      getOrderList(addDateRange(this.queryParams, this.timeScope)).then((res) => {
          this.tablList = res.rows;
          this.total = res.total;
        }
      );
    },
	setAction(){
		window.sessionStorage.setItem("activePath", '/ERPP');
		this.$emit('setlocation','/ERPP');
	},
    query() {
      this.handlgetList()
    },
    rest(){  
      this.timeScope = []
	  this.queryParams.shopId = null;
	  this.queryParams.orderState = null;
      this.handlgetList()
	  },
    close() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push({ path: "/" });
      })
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateUserPwd(this.user.oldPassword, this.user.newPassword).then(
            response => {
               this.$message.success(this.td("修改成功"));
               this.changePassWordCon = false
            }
          )
        }
      })
    },
    watchPassword() {
      if(this.$store.state.user.updatePassword == "0") {
        this.changePassWordCon = true
      }
    }
  }
}
</script>

<style scoped>
.el-row {
  border: 24px solid #F1F3FF;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.el-col {
  width: 20%;
}

.box {
  position: relative;
  height: 263px;
}
.icon {
  position: absolute;
  top: 50%;
  left: 48px;
  
  transform: translateY(-50%);
  width: 80px;
  height: 80px;
}
img {
  width: 80px;
  height: 80px;
  max-width: 80px;
}

.num {
  position: absolute;
  top: 20%;
  left: 128px;
  display: inline;

  margin-left: 20px;
  margin-top: 28px;
  width: 80px;
  height: 62px;
  font-size: 44px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 62px;
}
.tag {
  position: absolute;
  left: 128px;
  top: 56%;
  display: inline;
  margin-left: 20px;
  width: 120px;
height: 28px;
font-size: 20px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #999999;
line-height: 28px;
}

.tables {
  margin: 16px 24px 0 24px;
  background-color: #fff;
}
.tables .el-button {
  width: 74px;
  height: 32px;
}
.titles {
  padding: 7px 0 0 16px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  float: left;
}
h4 {
  margin: 0 0 22px 0;
}
.el-form {
  margin: 0 120px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.selectTime {
  width: 248px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
}
.el-table {
  padding: 0 16px;
}

</style>

