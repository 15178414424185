import request from '@/utils/request'
import { getToken } from "@/utils/auth"

export function getList(query) {
  return request({
    url: '/erp/order/getErpOrderList',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params : query
  })
}
 
export function getTitle() {
  return request({
    // url: '/erp/order/getCountErpOrder',
	url: '/erp/api/order/getOrderSum',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',

  })
}